import { startOfToday } from 'date-fns';
import { debounce } from 'lodash-es';
import { useSdk } from './use-sdk';
import { storeToRefs, defineStore } from 'pinia';
import { useTimeoutPoll , useOffsetPagination } from '@vueuse/core'
import { ref, readonly, unref, watch } from 'vue';
import { useCurrentUser } from '@/composables/use-auth';

const LAST_NOFIF_ID = 'lastNotifId';

export const useNotifsStore = defineStore('notifs', {
  state: () => ({
    initialized: false,
    notifsMap: new Map(),
    user: null,
    lastNotifId: null,
  }),
  getters: {
    notifs () {
      const arr = Array.from(this.notifsMap.values());
      return arr.sort((a, b) => a.createdAt - b.createdAt);
    },
  },
  actions: {
    async init () {
      if (this.initialized) return;
      const [user, lastNotifId] = localStorage.getItem(LAST_NOFIF_ID)?.split('::') || [];
      this.user = user || null;
      this.lastNotifId = lastNotifId || null;
      this.initialized = true;
    },
    async clearNotifs () {
      this.notifsMap.clear();
      this.user = null;
      this.lastNotifId = null;
    },
    async addNotifs (user, notifs) {
      // init store
      await this.init();

      // change of user, reset notifs
      if (this.user && this.user !== user) await this.clearNotifs();

      // delayed flush
      const debouncedLocalStorage = debounce((notif) => {
        localStorage.setItem(LAST_NOFIF_ID, `${user}::${notif.id}`);
      }, 300);

      // add notifs to store
      const newnotifs = [];
      for (const notif of notifs) {
        const existed = this.notifsMap.get(notif.id);
        this.notifsMap.set(notif.id, notif);
        if (existed) continue;
        if (!this.lastNotifId || notif.id > this.lastNotifId) {
          this.lastNotifId = notif.id;
          debouncedLocalStorage(notif);
          const seen = notif.seenBy?.includes(user);
          const todayOnwards = startOfToday().getTime() <= notif.createdAt;
          if (!seen && todayOnwards) {
            newnotifs.push(notif);
          }
        }
      }
      return newnotifs;
    },
  },
});

export function useNotifs () {
  const sdk = useSdk();
  const currentUser = useCurrentUser();

  const markNotifAsRead = async (notif) => {
    if (!currentUser.value?.uid) return;
    await sdk.update('notifications', notif.id, { seen: true });
  };

  const markAllNotifsAsRead = async () => {
    if (!currentUser.value?.uid) return;
    const query = {
      seenBy: { $ne: currentUser.value.uid },
    };
    await sdk.update('notifications', query, { seen: true });
  };

  const formatNotifsQuery = opts => {
    const { filters } = opts || {};
    const query = {};

    query.$sort = { createdAt: -1 };

    // filter only bookings
    query.type = 'booking/bookings';

    if (filters?.lastNotifId) {
      query.id = { $gt: filters.lastNotifId };
    }

    return query;
  };

  const fetchNotifsCount = async (opts) => {
    const query = formatNotifsQuery(opts);
    query.$limit = 0;
    const res = await sdk.list('notifications', query);
    return res.total;
  };

  const fetchNotifs = async (opts) => {
    const { page = 1, pageSize = 10 } = opts || {};
    const query = formatNotifsQuery(opts);

    // Add pagination
    query.$limit = pageSize;
    if (page > 1) query.$skip = (page - 1) * pageSize;

    // execute the query
    const res = await sdk.list('notifications', query);
    if (currentUser.value?.uid) {
      res.data = res.data.map((notif) => {
        notif.seen = !!notif.seenBy?.includes(currentUser.value.uid);
        return notif;
      });
    }
    return res.data;
  };

  return {
    markNotifAsRead,
    markAllNotifsAsRead,
    fetchNotifsCount,
    fetchNotifs,
  };
}

export function usePaginatedNotifs (opts) {
  const { fetchNotifs, fetchNotifsCount } = useNotifs();

  const filters = ref({ ...opts?.filters });
  const filtersChanged = ref(true);
  const total = ref(0);
  const items = ref([]);
  const loading = ref(false);

  watch(filters, () => {
    filtersChanged.value = true;
    fetchData();
  }, { deep: true });

  const fetchData = async (fopts) => {
    const {
      currentPage: page = currentPage.value,
      currentPageSize: pageSize = currentPageSize.value,
      filters: filtersRaw = unref(filters),
    } = fopts || {};
    try {
      loading.value = true;
      if (filtersChanged.value) {
        // reset items
        items.value = [];
        // fetch total count
        total.value = await fetchNotifsCount({
          filters: filtersRaw,
        });
        filtersChanged.value = false;
      }
      const resitems = await fetchNotifs({
        filters: filtersRaw,
        page,
        pageSize,
      });
      items.value = resitems;
    } catch (error) {
      console.error(`Failed to fetch bookings: ${error.message}`);
    } finally {
      loading.value = false;
    }
  };

  const {
    currentPage,
    currentPageSize,
    pageCount,
    isFirstPage,
    isLastPage,
    prev,
    next,
  } = useOffsetPagination({
    total,
    pageSize: opts?.pageSize || 10,
    onPageChange: fetchData,
    onPageSizeChange: fetchData,
  });

  // auto fetch
  fetchData();

  return {
    // ref
    currentPage,
    currentPageSize,
    pageCount,
    filters,
    // actions
    prev,
    next,
    fetchData,
    // readonly
    isFirstPage,
    isLastPage,
    loading: readonly(loading),
    total: readonly(total),
    items: readonly(items),
  };
}

export function useNotifsTailed (opts) {
  const { fetchNotifs } = useNotifs();
  opts = Object.assign({ 
    limit: 10,
    loadMoreInterval: 1000 * 3,
    fetchImmediate: true,
  }, opts);
  const onNewNotif = opts.onNewNotif || (() => {});

  const sdk = useSdk();
  const { clearNotifs, addNotifs } = useNotifsStore();
  const { notifs, lastNotifId } = storeToRefs(useNotifsStore());

  const loading = ref(false);

  const loadMore = async (lopts) => {
    const shouldNotify = !!lopts?.notify;
    try {
      loading.value = true;
      const currentUser = await sdk.currentUser();
      if (!currentUser) {
        await clearNotifs();
        return;
      }

      const res = await fetchNotifs({
        filters: { lastNotifId: lastNotifId.value },
      });
      const added = await addNotifs(currentUser.uid, res, onNewNotif);
      console.warn(`${added.length}/${res.length} new notifications added`)
      if (added.length && shouldNotify) {
        const tonotif = added.length > 5 ? added.slice(0, 5) : added;
        tonotif.forEach((notif) => onNewNotif(notif));
      }
      return added;
    } catch (err) {
      console.error(err);
    } finally {
      loading.value = false;
    }
  };

  if (opts.fetchImmediate) {
    onMounted(() => {
      useTimeoutPoll(() => {
        if (loading.value) return;
        console.log('loading more notifications...');
        loadMore({ notify: true });
      }, opts.loadMoreInterval, { immediate: true })
    })
  }

  return {
    loading: readonly(loading),
    notifs: readonly(notifs),
    loadMore,
  };
}
